import './assets/scss/NavTopAlertBar.scss'
// 3rd Party Packages
import React from 'react'

const NavTopAlertBar = () => {
  return (
    <>
      <a
        id='ms-alert-bart-link'
        href='https://www.mainstem.io/platform/supplier-core'
        target='_blank'
        rel='noopener noreferrer'
      >
        You are eligible for a free <strong>Supplier Core™</strong> with access
        to thousands of customers.
      </a>
    </>
  )
}

export default NavTopAlertBar
