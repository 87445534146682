import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import Layout from 'layout/'
import { apiStatusCheck } from 'api/statusCheck/statusCheck'

LogRocket.init(process.env.REACT_APP_LOGROCKET_PRIVATE_SUPPLIER_APP_ID)

apiStatusCheck().then((apiResponse) => {
  if (apiResponse.wasSuccessful) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_PRIVATE_SUPPLIER_APP_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: apiResponse.environment
    })
  }
})

// Set session URL for use later
LogRocket.getSessionURL((sessionURL) => {
  Sentry.setContext('session', {
    url: sessionURL
  })
  window.localStorage.setItem('logrocket_session_url', sessionURL)
})

ReactDOM.render(
  <BrowserRouter>
    <Layout />
  </BrowserRouter>,
  document.getElementById('root')
)
