import React, { Fragment, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import PageOrderDetails from 'pages/orders/details'

// Pages
const PageHome = React.lazy(() => import('pages/home'))
const PageOrderSubmit = React.lazy(() => import('pages/orders/submit'))
// const PageOrderDetails = React.lazy(() => import('pages/orders/details'))
const Page404 = React.lazy(() => import('pages/err/404'))

export const Routes = () => {
  return (
    <Suspense fallback={<Fragment />}>
      <Switch>
        <Route exact path='/'>
          <PageHome />
        </Route>
        <Route exact path='/orders/submit/:uuid'>
          <PageOrderSubmit />
        </Route>
        <Route exact path='/orders/details/:uuid/:invoiceUUID'>
          <PageOrderDetails />
        </Route>
        <Route>
          <Page404 />
        </Route>
      </Switch>
    </Suspense>
  )
}
