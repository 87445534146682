import React, { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Container,
  Button
} from 'reactstrap'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import ShippingCarriers from '../ShippingCarriers'
import { APIShippingContainersCreate } from 'api/shipping-containers/shipping-containers-create'
import ShippingTypes from '../ShippingTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-duotone-svg-icons'
import { LoadingButton } from '@mainstem/mainstem-react-app'

const ModalShipmentTracking = ({
  invoiceId,
  showModal,
  setShowModal,
  onSuccess
}) => {
  const [carrier, setCarrier] = useState('')
  const [shippingType, setShippingType] = useState('')
  const [trackingNumber, setTrakingNumber] = useState('')
  const [loadingSave, setLoadingSave] = useState(false)

  const onModalClose = () => {
    setShowModal(false)
  }

  const handleSave = () => {
    setLoadingSave(true)
    const apiRequest = {
      invoiceId,
      carrier,
      shippingType,
      trackingNumber
    }
    APIShippingContainersCreate(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success('Successfully created new tracking info.')
        setLoadingSave(false)
        onSuccess()
        onModalClose()
      } else {
        toast.error('Failed to create new tracking info.')
        setLoadingSave(false)
      }
    })
  }

  return (
    <Modal centered isOpen={showModal} toggle={onModalClose}>
      <ModalHeader toggle={onModalClose}>
        Add Shipment Tracking Infomation
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Shipping Carrier</Label>
              <ShippingCarriers
                onSelected={(selectedOption) => {
                  setCarrier(selectedOption)
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Shipping Method</Label>
              <ShippingTypes
                onSelected={(selectedOption) => {
                  setShippingType(selectedOption)
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>Shipment Tracking Number</Label>
              <Input
                text='text'
                onChange={({ target }) => {
                  setTrakingNumber(target.value)
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className='px-0'>
        <Container fluid>
          <Row>
            <Col md={6} className='align-self-center text-left'>
              <Button color='danger' onClick={onModalClose}>
                Cancel
              </Button>
            </Col>
            <Col md={6} className='align-self-center text-right'>
              <LoadingButton
                color='primary'
                loading={loadingSave}
                onClick={handleSave}
              >
                <FontAwesomeIcon icon={faSave} />
                &nbsp;Save
              </LoadingButton>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  )
}

ModalShipmentTracking.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default ModalShipmentTracking
