// CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import '@mainstem/mainstem-react-app/dist/index.css'
import './assets/scss/layout.scss'
// 3rd Party Packages
import React, { Suspense, Fragment } from 'react'
import { MainStemBase } from '@mainstem/mainstem-react-app'
// App Config
import { Routes } from 'routes'
import { baseURL } from 'config'
import NavTopAlertBar from './components/nav-top/alert-bar'
// Local Components
const NavTop = React.lazy(() => import('./components/nav-top'))
const Impersonate = React.lazy(() => import('pages/impersonate'))

const Layout = () => {
  const appSettings = {
    affiliateUUID: null,
    appName: 'Private Supplier',
    baseURL,
    checkedForInitialUser: true,
    forceLogin: false,
    impersonationComponent: <Impersonate />,
    links: [],
    loadingLogin: false,
    loggedInUser: null,
    loginContent: null,
    loginLogo: null,
    logo: null,
    navLeft: false,
    navTopAlertBar: <NavTopAlertBar />,
    navTop: <NavTop />,
    onLogin: () => {},
    onLogout: () => {},
    routes: <Routes />,
    showNavLeft: false,
    userToken: null
  }

  return (
    <Suspense fallback={<Fragment />}>
      <MainStemBase settings={appSettings} />
    </Suspense>
  )
}

export default Layout
