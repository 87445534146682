import React, { Fragment } from 'react'
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Row,
  Table
} from 'reactstrap'

import classNames from 'classnames'
import {
  PrettyTab,
  PrettyTabs,
  PrettyNumber,
  PrettyDateTime,
  PrettyCurrency,
  GetCarrierLogoURL
} from '@mainstem/mainstem-react-app'
// Global Assets
import noImageAvailable from 'assets/img/picture-not-available.jpg'
import MainStemLogo from 'assets/img/mainstem-logo.png'

const FulfillmentMethods = ({ fulfillmentMethod, orderId }) => {
  // We could allow them to update Line Item pricing/Tax/Shipping here

  if (!fulfillmentMethod) return <Fragment />

  return (
    <Card className={classNames('mb-3')}>
      <CardHeader>
        <Row>
          <Col className='text-left align-self-center'>
            <Media
              className='border'
              left
              object
              src={
                fulfillmentMethod.name && fulfillmentMethod.logoURL
                  ? fulfillmentMethod.logoURL
                  : MainStemLogo
              }
              style={{ maxHeight: '40px', maxWidth: '100%' }}
            />
            <br />
            {/* <h4>Fulfilled By : {fulfillmentMethod.name ? fulfillmentMethod.name : 'MainStem'}</h4> */}
            <h5 className='mt-2'>
              Invoice: #{orderId}-{fulfillmentMethod.id}
            </h5>
          </Col>
          <Col md='auto' className='text-right align-self-center'>
            Sub Total:
            <br />
            <PrettyCurrency value={fulfillmentMethod.subTotal} />
          </Col>
          <Col md='auto' className='text-right align-self-center'>
            Shipping:
            <br />
            <PrettyCurrency value={fulfillmentMethod.shipping} />
          </Col>
          <Col md='auto' className='text-right align-self-center'>
            Tax:
            <br />
            <PrettyCurrency value={fulfillmentMethod.salesTax} />
          </Col>
          <Col md='auto' className='text-right align-self-center'>
            Shipped On:
            <br />
            {fulfillmentMethod.dateShipped ? (
              <PrettyDateTime datetime={fulfillmentMethod.dateShipped} />
            ) : (
              <Badge color='soft-danger'>Not Shipped Yet</Badge>
            )}
          </Col>
        </Row>
        <hr className='m-0' />
      </CardHeader>
      <CardBody className='pt-0'>
        <br />
        <PrettyTabs>
          <PrettyTab title='Items' length={fulfillmentMethod.lineItems.length}>
            <div className='table-responsive fs--1'>
              <Table striped className='border border-200'>
                <thead className='bg-200 text-900'>
                  <tr>
                    <th className='border-0 text-left' />
                    <th className='border-0'>Products</th>
                    <th className='border-0 text-center'>Quantity</th>
                    <th className='border-0 text-right'>Price</th>
                    <th className='border-0 text-right'>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {fulfillmentMethod.lineItems.map((lineItem, indexLineItem) =>
                    lineItem.quantity > 0 ? (
                      <tr key={indexLineItem}>
                        <td className='align-middle text-center border-200'>
                          <Media
                            object
                            src={
                              lineItem.product.imageURL
                                ? lineItem.product.imageURL
                                : noImageAvailable
                            }
                            style={{
                              maxHeight: '40px',
                              maxWidth: '100%'
                            }}
                            className='border'
                          />
                        </td>
                        <td className='align-middle border-200'>
                          <h6 className='mb-0 text-nowrap'>
                            {lineItem.product.name}
                          </h6>
                          <p className='mb-0'>{lineItem.product.type}</p>
                        </td>
                        <td className='align-middle text-center border-200'>
                          <PrettyNumber value={lineItem.quantity} />
                        </td>
                        <td className='align-middle text-right border-200'>
                          <PrettyCurrency value={lineItem.price} />
                        </td>
                        <td className='align-middle text-right border-200'>
                          <PrettyCurrency
                            value={lineItem.price * lineItem.quantity}
                          />
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </Table>
            </div>
          </PrettyTab>
          {fulfillmentMethod.shipments.length > 0 ? (
            <PrettyTab
              title='Shipments'
              length={fulfillmentMethod.shipments.length}
            >
              <Row>
                {fulfillmentMethod.shipments.map((shipment, shipmentIndex) => {
                  return (
                    <Col
                      className='fs--1'
                      md={{ size: 'auto' }}
                      key={shipmentIndex}
                    >
                      <div className='notification-avatar'>
                        <div className='mr-3'>
                          <img
                            className=''
                            style={{ height: '35px' }}
                            src={GetCarrierLogoURL(shipment.carrier)}
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='notification-body'>
                        <p className='mb-1'>
                          <strong>{shipment.carrier}</strong> shipped via : "
                          {shipment.carrierType}"
                        </p>
                        <span className='notification-time'>
                          <span className='mr-1' role='img' aria-label='Emoji'>
                            Tracking Number:
                          </span>
                          <br />
                          <span className='btn-link'>
                            {shipment.trackingNumber}
                          </span>
                        </span>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </PrettyTab>
          ) : null}
        </PrettyTabs>
      </CardBody>
    </Card>
  )
}

export default FulfillmentMethods
