import React from 'react'
import Select from 'react-select'

const options = [
  {
    label: 'Ground',
    value: 'Ground'
  },
  {
    label: '2 Day',
    value: '2Day'
  },
  {
    label: 'Next Day',
    value: 'NextDay'
  }
]

export default function ShippingType({ onSelected }) {
  return (
    <Select
      onChange={(selectedOption) => {
        onSelected(selectedOption.value)
      }}
      options={options}
    />
  )
}
