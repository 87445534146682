// 3rd Party Packages
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext
} from 'react'
import { Col, Row, ListGroup, ListGroupItem } from 'reactstrap'
import { toast } from 'react-toastify'
import { Loader } from '@mainstem/mainstem-react-app'
import { faCheck } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// API's
import { APIInvoiceDetails } from 'api/invoice/invoice-details.js'
// App Config
import { AppContext } from 'context'
// Local Components and Assets
import FulfillmentMethods from './components/FulfillmentMethods'
import OrderDetailsHeader from './components/OrderDetailsHeader'
import { useParams, useHistory } from 'react-router-dom'

const PageOrderDetails = () => {
  const { loggedInUser } = useContext(AppContext)
  const [firstLoad, setFirstLoad] = useState(true)
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState({
    fulfillmentMethods: [],
    lineItems: [],
    billTo: {},
    shipTo: {}
  })
  const { uuid, invoiceUUID } = useParams()
  const history = useHistory()

  const getOrderDetailsCall = useCallback(() => {
    APIInvoiceDetails({ uuid, invoiceUUID }).then((response) => {
      if (response.wasSuccessful) {
        setOrder(response)
        setLoading(false)
      } else {
        history.push(`/invoice-was-not-found`)
        if (response.message) toast.error(response.message)
      }
    })
  }, [uuid, history, invoiceUUID])

  useEffect(() => {
    if (firstLoad && uuid) {
      getOrderDetailsCall()
      setFirstLoad(false)
    }
  }, [firstLoad, uuid, loggedInUser, history, getOrderDetailsCall])

  const { fulfillmentMethod } = order

  return (
    <Fragment>
      <OrderDetailsHeader
        order={order}
        loggedInUser={loggedInUser}
        loading={loading}
        fulfillmentMethod={fulfillmentMethod}
        loadDetailsFn={getOrderDetailsCall}
      />
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Row>
            <Col className='col-md-8'>
              <FulfillmentMethods
                fulfillmentMethod={fulfillmentMethod}
                orderId={order.id}
              />
            </Col>
            <Col className='col-md-4'>
              <div className='card'>
                <div className='card-header'>
                  <h5 className='m-0'>Become A Verified Supplier!</h5>
                </div>

                <div className='card-body'>
                  <p>
                    Becoming a verified supplier on MainStem is a simple
                    process. Connect the products, inventory and pricing you
                    want to sell at and we will instantly release your products
                    as a purchasbale option to all of MainStem's e-commerce
                    websites, affiliates, and enterprise purchasing platform.
                  </p>
                  <ListGroup>
                    <ListGroupItem>
                      <FontAwesomeIcon icon={faCheck} />
                      &nbsp;Your products released to thousands of new customers
                    </ListGroupItem>
                    <ListGroupItem>
                      <FontAwesomeIcon icon={faCheck} />
                      &nbsp;Integration &amp; Plugins to your existing software
                    </ListGroupItem>
                    <ListGroupItem>
                      <FontAwesomeIcon icon={faCheck} />
                      &nbsp;Automated payments from MainStem
                    </ListGroupItem>
                    <ListGroupItem>
                      <FontAwesomeIcon icon={faCheck} />
                      &nbsp;Access to MainStem's supplier portal with market
                      insights, product performace and advertising capabilities.
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </div>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  )
}
export default PageOrderDetails
