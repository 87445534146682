import React from 'react'
import Select from 'react-select'

const options = [
  {
    label: 'DHL',
    value: 'DHL'
  },
  {
    label: 'FedEx',
    value: 'FedEx'
  },
  {
    label: 'Oak Harbor Freight',
    value: 'Oak Harbor Freight'
  },
  {
    label: 'UPS',
    value: 'UPS'
  },
  {
    label: 'USPS',
    value: 'USPS'
  }
]

export default function ShippingCarriers({ onSelected }) {
  return (
    <Select
      onChange={(selectedOption) => {
        onSelected(selectedOption.value)
      }}
      options={options}
    />
  )
}
