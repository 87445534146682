import axios from 'axios'
import * as Sentry from '@sentry/react'

const mainstemAuthToken = window.localStorage.getItem('mainstem_user_token')

// LogRocket Session URL
const sessionURL = window.localStorage.getItem('logrocket_session_url')

export const baseURL = process.env.REACT_APP_BASE_API_URL
export const payQwickClientURL = process.env.REACT_APP_PAYQWICK_CLIENT_URL

export const apiURL = '/api/privateSupplier/'
export const globalURL = '/api/global/'

export const headers = {
  Authorization: `Bearer ${mainstemAuthToken || ''}`,
  'X-SessionURL': sessionURL,
  'X-Platform': 'Private Supplier App'
}

function logUnsuccessfulResponses(response) {
  if (response.data && !response.data.wasSuccessful) {
    const msg =
      response.data.message ||
      response.data.error ||
      'API Response Unsuccessful'
    Sentry.captureException(new Error(msg), {
      contexts: {
        response: response.data,
        url: response.config.url
      }
    })
  }
  return response
}
// Set Axios defaults
const HTTP = axios.create({
  baseURL: baseURL + apiURL,
  headers: headers
})
HTTP.interceptors.response.use(logUnsuccessfulResponses)

// Set Axios defaults
const HTTPBase = axios.create({
  baseURL: baseURL,
  headers: headers
})
HTTPBase.interceptors.response.use(logUnsuccessfulResponses)

export { HTTP, HTTPBase }
