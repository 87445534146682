import React, { Fragment, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/pro-duotone-svg-icons'

const AcceptedAlertModal = ({ onAcceptedAction, showModal, setShowModal }) => {
  const [note, setNote] = useState('')
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleInvoiceAcceptedAction = () => {
    setShowModal(false)
    onAcceptedAction(note)
  }

  return (
    <Modal isOpen={showModal} centered toggle={handleCloseModal} size='lg'>
      <ModalBody className='p-0'>
        <Fragment>
          <Row noGutters>
            <Col>
              <Alert color='success' className='mb-0'>
                <h1>Accept Invoice?</h1>
                <p>
                  We will notify the customer that you did accept the conditions
                  of the invoice.
                </p>
              </Alert>
            </Col>
          </Row>
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <Container fluid>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Note For Customer</Label>
                <Input
                  type='textarea'
                  value={note}
                  onChange={({ target }) => {
                    setNote(target.value)
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row noGutters>
            <Col md={6} className='text-left'>
              <Button color='danger' onClick={handleCloseModal}>
                Close
              </Button>
            </Col>
            <Col md={6} className='text-right'>
              <Button color='success' onClick={handleInvoiceAcceptedAction}>
                <FontAwesomeIcon icon={faDollarSign} />
                &nbsp;Accept Invoice
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  )
}

export default AcceptedAlertModal
