// 3rd Party Packages
import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Media,
  Row
} from 'reactstrap'
import {
  Background,
  Loader,
  PermissiveButton,
  PrettyAddress,
  PrettyCurrency,
  PrettyDateTime,
  PrettyPhone
} from '@mainstem/mainstem-react-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFilePdf,
  faUser,
  faUserShield,
  faBan,
  faDollarSign,
  faShippingFast
} from '@fortawesome/pro-duotone-svg-icons'
// Global App Config
import { baseURL } from 'config'
// API's
import { APIInvoiceStatusUpdate } from 'api/invoice/invoice-status-update'
// Components
import AcceptedAlertModal from '../AcceptedAlertModal'
import DeclinedAlertModal from '../DeclinedAlertModal'
import ModalShipmentTracking from '../ModalShipmentTracking'
import { toast } from 'react-toastify'

const OrderDetailsHeader = ({
  fulfillmentMethod,
  loading,
  order,
  loadDetailsFn
}) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [localLoading, setLocalLoading] = useState(false)
  const [showDeclinedModal, setShowDeclinedModal] = useState(false)
  const [showAcceptedModal, setShowAcceptedModal] = useState(false)
  const [showTrackingModal, setShowTrackingModal] = useState(false)
  const PrivateSupplierStatuses = {
    Accepted: 'Accepted',
    Declined: 'Declined',
    Modified: 'Modified'
  }
  const PrivateSupplierStatusColors = {
    Pending: 'soft-info',
    Accepted: 'soft-success',
    Declined: 'soft-danger',
    Modified: 'soft-warning'
  }

  const handleInvoiceApproved = (note) => {
    var apiRequest = {
      status: PrivateSupplierStatuses.Accepted,
      fulfillmentId: fulfillmentMethod.id,
      note: note
    }
    setLocalLoading(true)
    APIInvoiceStatusUpdate(apiRequest).then((response) => {
      setLocalLoading(false)
      if (response.wasSuccessful) {
        toast.success('Updated invoice with accepted status!')
      } else {
        toast.error('Issue occured while updating invoices status.')
      }
      loadDetailsFn()
    })
  }

  const handleInvoiceDeclined = (note) => {
    var apiRequest = {
      status: PrivateSupplierStatuses.Declined,
      fulfillmentId: fulfillmentMethod.id,
      note: note
    }
    setLocalLoading(true)
    APIInvoiceStatusUpdate(apiRequest).then((response) => {
      setLocalLoading(false)
      if (response.wasSuccessful) {
        toast.success('Updated invoice with declined status')
      } else {
        toast.error('Issue occured while updating invoices status.')
      }
      loadDetailsFn()
    })
  }

  useEffect(() => {
    if (!loading && firstLoad && order) {
      setFirstLoad(false)
    }
  }, [firstLoad, order, loading])

  return (
    <Fragment>
      <Card
        className={classNames(
          'mb-3',
          order.status === 'Cancelled' ? 'bg-soft-danger' : null
        )}
      >
        <CardBody>
          <Background image={2} />
          <Row>
            <Col lg={8} sm={12} xs={12}>
              <h5>{`Purchase Order: #${order.id ? order.id : ''}`}</h5>
              <hr />
              {loading ? (
                <Loader />
              ) : (
                <Row>
                  <Col xs={4}>
                    <Media>
                      <FontAwesomeIcon
                        icon={faUser}
                        transform='down-5'
                        className='text-bloo mr-2'
                      />
                      <Media body>
                        <h5 className='fs-0 mb-1'>Created On</h5>
                        <p className='fs--1 mt-1'>
                          <PrettyDateTime datetime={order.dateCreated} />
                          <br />
                          <span className='fs--1 text-500'>
                            By: {order.userCreatedBy}
                          </span>
                        </p>
                      </Media>
                    </Media>
                  </Col>
                  <Col xs={4}>
                    <Media>
                      <FontAwesomeIcon
                        icon={faUserShield}
                        transform='down-5'
                        className='text-bloo mr-2'
                      />
                      <Media body>
                        <h5 className='fs-0 mb-1'>Approved On</h5>
                        {order.dateApproved ? (
                          <Fragment>
                            <p className='fs--1 mt-1'>
                              <PrettyDateTime datetime={order.dateApproved} />
                              <br />
                              <span className='fs--1 text-500'>
                                By: {order.userApprovedBy}
                              </span>
                            </p>
                          </Fragment>
                        ) : order.status === 'Cancelled' ? (
                          <Badge color='soft-danger'>Cancelled</Badge>
                        ) : order.approvalStatus === 'SavedCart' ? (
                          <Badge color='soft-danger'>Not Submitted Yet</Badge>
                        ) : (
                          <Badge color='soft-danger'>Not Approved Yet</Badge>
                        )}
                      </Media>
                    </Media>
                  </Col>
                  <Col xs={4}>
                    <Media>
                      <Media body>
                        <h5 className='fs-0 mb-1'>Supplier Approval Status</h5>
                        <p className='fs--1 mt-1'>
                          <Badge
                            className='d-block'
                            color={
                              PrivateSupplierStatusColors[order.invoiceStatus]
                            }
                          >
                            {order.invoiceStatus}
                          </Badge>
                        </p>
                      </Media>
                    </Media>
                  </Col>
                </Row>
              )}
            </Col>
            <Col lg={4}>
              <div className='h-100 text-right'>
                <ButtonGroup vertical>
                  {fulfillmentMethod ? (
                    <Button
                      color='primary'
                      size='sm'
                      className='px-4 px-sm-5 mb-3'
                      href={`${baseURL}/InvoiceView/AsPDF/?id=${order.uuid}&fulfillmentId=${fulfillmentMethod.uuid}`}
                      target='_blank'
                      block
                    >
                      <FontAwesomeIcon icon={faFilePdf} />
                      &nbsp; View Order PDF
                    </Button>
                  ) : null}
                  {order.invoiceStatus === 'Accepted' ? (
                    <Fragment>
                      <PermissiveButton
                        allow
                        loading={loading || localLoading}
                        color='success'
                        size='sm'
                        className='px-4 px-sm-5 mb-3'
                        block
                        target='_blank'
                        onClick={() => setShowTrackingModal(true)}
                      >
                        <FontAwesomeIcon icon={faShippingFast} />
                        &nbsp; Add Shipment Tracking
                      </PermissiveButton>
                    </Fragment>
                  ) : null}
                  {order.invoiceStatus === 'Pending' ? (
                    <Fragment>
                      <PermissiveButton
                        allow
                        loading={loading || localLoading}
                        color='success'
                        size='sm'
                        className='px-4 px-sm-5 mb-3'
                        block
                        target='_blank'
                        onClick={() => setShowAcceptedModal(true)}
                      >
                        <FontAwesomeIcon icon={faDollarSign} />
                        &nbsp; Accept Order
                      </PermissiveButton>
                      <PermissiveButton
                        allow
                        loading={loading || localLoading}
                        color='danger'
                        size='sm'
                        className='px-4 px-sm-5 mb-3'
                        block
                        target='_blank'
                        onClick={() => setShowDeclinedModal(true)}
                      >
                        <FontAwesomeIcon icon={faBan} />
                        &nbsp; Decline Order
                      </PermissiveButton>
                    </Fragment>
                  ) : null}
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className='mb-3'>
        <CardBody>
          {loading ? (
            <Loader />
          ) : (
            <Row>
              <Col lg={3}>
                <Row>
                  <Col className='text-left align-self-center'>
                    <h5 className='mb-0'>Billing Address</h5>
                  </Col>
                </Row>
                <br />
                {loading ? (
                  <Loader />
                ) : (
                  <Fragment>
                    <h6 className='mb-2'>{order.billTo.name}</h6>
                    <p className='mb-1 fs--1'>
                      <PrettyAddress
                        address={{
                          address1: order.billTo.address1,
                          address2: order.billTo.address2,
                          city: order.billTo.city,
                          state: order.billTo.state,
                          zip: order.billTo.zip,
                          country: order.billTo.country
                        }}
                      />
                    </p>
                  </Fragment>
                )}
              </Col>
              <Col lg={3}>
                <Row>
                  <Col className='text-left align-self-center'>
                    <h5 className='mb-0'>Shipping Address</h5>
                  </Col>
                </Row>
                <br />
                {loading ? (
                  <Loader />
                ) : (
                  <Fragment>
                    <h6 className='mb-2'>{order.shipTo.name}</h6>
                    <p className='mb-0 fs--1'>
                      <PrettyAddress
                        address={{
                          address1: order.shipTo.address1,
                          address2: order.shipTo.address2,
                          city: order.shipTo.city,
                          state: order.shipTo.state,
                          zip: order.shipTo.zip,
                          country: order.shipTo.country
                        }}
                      />
                    </p>
                  </Fragment>
                )}
              </Col>
              <Col lg={3}>
                <h5 className='mb-1 fs-0'>Email</h5>
                <div className='mb-0 fs--1'>
                  <a href={`mailto:${order.email}`}>{order.email}</a>
                </div>
                <br />
                <h5 className='mb-1 fs-0'>Phone</h5>
                <div className='mb-0 fs--1'>
                  <a href={`tel:${order.phone}`}>
                    <PrettyPhone phone={order.phone} />
                  </a>
                </div>
              </Col>
              <Col lg={3}>
                {loading ? (
                  <Loader />
                ) : (
                  <Fragment>
                    <table className='table table-sm table-borderless fs--1 text-right'>
                      <tbody>
                        <tr>
                          <th className='text-900'>Subtotal:</th>
                          <td className='font-weight-semi-bold'>
                            <PrettyCurrency value={order.subTotal} />
                          </td>
                        </tr>
                        <tr>
                          <th className='text-900'>Shipping:</th>
                          <td className='font-weight-semi-bold'>
                            <PrettyCurrency value={order.shipping} />
                          </td>
                        </tr>
                        <tr>
                          <th className='text-900'>Tax:</th>
                          <td className='font-weight-semi-bold'>
                            <PrettyCurrency value={order.salesTax} />
                          </td>
                        </tr>
                        {order.discount > 0 ? (
                          <tr>
                            <th className='text-900'>Discount:</th>
                            <td className='font-weight-semi-bold'>
                              <PrettyCurrency value={order.discount} />
                            </td>
                          </tr>
                        ) : null}
                        <tr className='border-top'>
                          <th className='text-900'>Total:</th>
                          <td className='font-weight-semi-bold'>
                            <PrettyCurrency value={order.orderTotal} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Fragment>
                )}
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      <AcceptedAlertModal
        onAcceptedAction={handleInvoiceApproved}
        showModal={showAcceptedModal}
        setShowModal={setShowAcceptedModal}
      />
      <DeclinedAlertModal
        onDeclinedAction={handleInvoiceDeclined}
        showModal={showDeclinedModal}
        setShowModal={setShowDeclinedModal}
      />
      {fulfillmentMethod ? (
        <ModalShipmentTracking
          invoiceId={fulfillmentMethod.id}
          showModal={showTrackingModal}
          setShowModal={setShowTrackingModal}
          onSuccess={loadDetailsFn}
        />
      ) : (
        ''
      )}
    </Fragment>
  )
}

OrderDetailsHeader.propTypes = {
  fulfillmentMethod: PropTypes.object,
  lineItems: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  onOrderUpdate: PropTypes.func.isRequired,
  onPayForOrder: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired
}

export default OrderDetailsHeader
